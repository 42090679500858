@import-normalize;
@import 'tippy.js/dist/tippy.css';
@import 'https://js.arcgis.com/4.23/@arcgis/core/assets/esri/themes/light/main.css';

*,
::after,
::before {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #212529;
    line-height: 1.5;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    color: #0d6efd;
    text-decoration: none;
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}

textarea {
    font-family: inherit;
}

pre {
    font: inherit;
    margin: 0;
    white-space: pre-wrap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

table {
    border-collapse: separate;
    border-spacing: 0;
}

.table tr:nth-child(even) {
    /* background-color: rgb(240, 240, 240); */
    background-color: white;
}

.table tr:nth-child(odd) {
    background-color: white;
}

.table th {
    font-weight: 600;
    padding: 0.3rem 0.6rem;
    text-align: left;
    vertical-align: bottom;
}

.table td {
    padding: 0.3rem 0.6rem;
    /* background-color: white; */
    border-bottom: 1px solid rgb(210, 210, 210);
}

.table thead tr:last-of-type th {
    border-bottom: 2px solid rgb(180, 180, 180);
}

.table tfoot th {
    border-top: 1px solid rgb(230, 230, 230);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}
