.modalStyle {
    position: fixed;
    left: 0;
    right: 0;
    top: -200px;
    bottom: 0;
    background-color: rgba(106, 106, 106, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.modalHeader {
    display: flex;
    padding: 5px 10px 10px 10px;
}

.modalTitle {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.modalBody {
    padding: 10px;
    border-top: 1px solid #eee;
}

.modalCloser {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.modalContent {
    background-color: white;
    padding: 1rem;
    border-radius: 0.25rem;
    max-height: 60%;
    overflow: auto;
}
